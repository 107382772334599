<!-- eslint-disable no-console -->
<script>
import api from "../common/axios";
import { i18n } from "../main";
import firebase from "firebase/app";
import "firebase/messaging";
/**
 * Topbar component
 */
export default {
  data() {
    return {
      user: {
        profile_picture: null,
      },
      config: {
        apiNotification: "notification?orderby=id&sort=desc&per_page=10&page=1",
        showNotification: "notification/",
      },
      notifications: {},
      notif_count: 0,
    };
  },
  methods: {
    redirectLink(i) {
      let type = i.data.type;
      let id_data = i.data.id;
      let _ = this;
      // console.log(type, id_data);
      switch (type) {
        case "job":
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/job/${id_data}/show` });
          break;
        case "registration_lc":
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/logistic/${id_data}/show` });
          break;
        case "registration_truck_owner":
        case "suspended_truck_owner": {
          _.getNotifDetail();
          _.$router.push({ path: `/truck-owner/${id_data}/show` });
          break;
        }
        case "registration_driver":
        case "suspended_driver": {
          _.getNotifDetail(i.id);
          _.$router.push({ path: `/truck-driver/${id_data}/show` });
          break;
        }
      }
    },
    receiveMessage() {
      try {
        firebase.messaging().onMessage((payload) => {
          console.log("payload ", payload);
          this.$toast("Notifikasi Baru", {
            icon: "fa fa-check",
            type: "success",
          });

          setTimeout(() => {
            this.getNotif();
          }, 3000);
        });
      } catch (e) {
        console.log(e);
      }
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    changeLanguage(obj) {
      i18n.locale = obj;
      localStorage.setItem("language", obj);
    },
    // getItemsData() {
    //   let _ = this;
    //   api.get("/profile").then((response) => {
    //     _.user = response.data.data;
    //   });
    // },

    getNotif() {
      let _ = this;
      api.get(_.config.apiNotification).then((response) => {
        _.notifications = response.data.data.rows;
        _.notif_count = response.data.data.total_unread;
      });
    },
    getNotifDetail(id) {
      let _ = this;
      api.get(_.config.showNotification + id).then(() => {
        _.getNotif();
      });
    },
  },
  created() {
    let _ = this;
    this.user = this.$store.state.auth.currentUser.data;
    _.getNotif();
  },
  async beforeMount() {
    // let id_user = this.$store.state.auth.currentUser.data.id;
    // await this.$OneSignal
    //   .init({ appId: process.env.VUE_APP_ONESIGNAL_APP_ID })
    //   .then(() => {
    //     this.$OneSignal.sendTag("user_id", id_user);
    //   });
    try {
      firebase
        .messaging()
        .requestPermission()
        .then(() => {
          // console.log("Notification permission granted");
          firebase
            .messaging()
            .getToken()
            .then((token) => {
              fetch(
                "https://iid.googleapis.com/iid/v1/" +
                  token +
                  "/rel/topics/" +
                  this.user.id,
                {
                  method: "POST",
                  headers: new Headers({
                    Authorization:
                      "key=" + process.env.VUE_APP_FIREBASE_APP_SERVER,
                  }),
                }
              )
                .then((response) => {
                  this.receiveMessage();
                  if (response.status < 200 || response.status >= 400) {
                    throw (
                      "Error subscribing to topic: " +
                      response.status +
                      " - " +
                      response.text()
                    );
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            });
        })
        .catch((err) => {
          console.log("Unable to get token ", err);
        });
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/AEX-Logo-white.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/AEX-Logo-white.png" alt height="28" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item"
          id="vertical-menu-btn"
          @click="toggleMenu()"
        >
          <i class="mdi mdi-menu"></i>
        </button>

        <div class="d-none d-sm-block"></div>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block d-lg-none ml-2">
          <button
            type="button"
            class="btn header-item noti-icon"
            id="page-header-search-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="mdi mdi-magnify"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- <b-dropdown
          class="d-none d-md-block ml-2"
          toggle-class="header-item"
          right
          variant="white"
        >
          <template v-slot:button-content>
            <div v-if="$i18n.locale == 'id'">
              <img
                class="mr-2"
                src="@/assets/images/flags/ind.png"
                alt="Header Language"
                height="16"
              />
              Bahasa
              <span class="mdi mdi-chevron-down"></span>
            </div>
            <div v-else>
              <img
                class="mr-2"
                src="@/assets/images/flags/us_flag.jpg"
                alt="Header Language"
                height="16"
              />
              English
              <span class="mdi mdi-chevron-down"></span>
            </div>
          </template>

          <a
            href="javascript:void(0);"
            @click="changeLanguage('id')"
            class="dropdown-item notify-item"
          >
            <img
              src="@/assets/images/flags/ind.png"
              alt="user-image"
              class="mr-1"
              height="12"
            />
            <span class="align-middle">Bahasa</span>
          </a>
          <a
            href="javascript:void(0);"
            @click="changeLanguage('en')"
            class="dropdown-item notify-item"
          >
            <img
              src="@/assets/images/flags/us_flag.jpg"
              alt="user-image"
              class="mr-1"
              height="12"
            />
            <span class="align-middle">English</span>
          </a>
          item
        </b-dropdown> -->

        <!-- <div class="dropdown d-none d-lg-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="mdi mdi-fullscreen"></i>
          </button>
        </div> -->
        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item noti-icon"
          menu-class="dropdown-menu-lg p-0"
          right
          variant="white"
        >
          <template v-slot:button-content>
            <i class="mdi mdi-bell-outline"></i>
            <span class="badge badge-danger badge-pill">{{ notif_count }}</span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">
                  {{ $t("topbar.notification") }} ({{ notif_count }})
                </h5>
              </div>
            </div>
          </div>
          <div data-simplebar style="max-height: 230px">
            <div class="text-reset notification-item">
              <div
                class="media"
                style="cursor: pointer"
                v-for="(i, index) in notifications"
                :key="index"
                @click="redirectLink(i)"
              >
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="mdi mdi-email"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ i.subject }}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ i.body }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-2">
            <b-button
              type="button"
              to="/notif-center"
              variant="primary"
              class="w-100"
              >Pusat Notifikasi</b-button
            >
          </div>
        </b-dropdown>

        <b-dropdown
          class="d-inline-block"
          right
          toggle-class="header-item"
          variant="white"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              :src="user.profile_picture"
              alt="Header Avatar"
            />
          </template>
          <a class="dropdown-item" href="/profile">
            <i
              class="mdi mdi-account-circle font-size-17 align-middle mr-1"
            ></i>
            Profile
          </a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="/logout">
            <i
              class="mdi mdi-logout font-size-17 align-middle mr-1 text-danger"
            ></i>
            Logout
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
